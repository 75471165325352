
import { useContext, useState } from 'react'

import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'
import { getProperUrl } from 'Contexts/VehicleContext/CoreVehicleRequests'

import Button from 'Clutch/Molecules/Button'

import Svg from 'Clutch/Atoms/Svg'

import {
  fitmentStates,
  useFitmentStateContext,
} from '../../../../providers/FitmentStateProvider'
import { getVehiclePlaceholder } from '../../desktop-cta/ymm-selector/tab-content/tabContentUtils'
import styles from './MobileGarageButton.module.scss'

const MobileGarageButton = ({ audience }) => {
  const [loading, setLoading] = useState(false)

  const { fitmentState, selectedCustomerProject, setCurrentTabForAudience } =
    useFitmentStateContext()
  const { dispatch: navigationDispatch } = useContext(NavigationContext)

  const placeholder = getVehiclePlaceholder(selectedCustomerProject, audience)
  const contentClassNames = `${styles.content} p p-color-contrast p-bold`
  let buttonProps = null

  switch (fitmentState) {
    case fitmentStates.vehicleSelected:
      buttonProps = {
        onClick: (e) => {
          e.stopPropagation()
        },
        isWaiting: loading,
        children: (
          <div className={styles.vehicleSelectedWrapper}>
            <p
              className={`${styles.selectedCustomerProjectContent} ${styles.selectedCustomerProjectText} p p-color-contrast p-bold`}
              style={{ flexGrow: 1, textAlign: 'left' }}
              onClick={(e) => {
                e.stopPropagation()
                setLoading(true)
                const url = getProperUrl(selectedCustomerProject)
                window.location.href = url ?? '/shop/all'
              }}
            >
              {placeholder}
            </p>
            <Svg
              size={1}
              icon={'chevron'}
              tone={'contrast'}
              style={{ marginLeft: '0.25rem', transform: 'rotate(90deg)' }}
              onClick={(e) => {
                e.stopPropagation()
                navigationDispatch({
                  type: 'setCustomerProjectDrawerState',
                  payload: {
                    garageState: {
                      isCustomerProjectDrawerOpen: true,
                    },
                  },
                })
              }}
            />
          </div>
        ),
      }
      break
    case fitmentStates.noVehicleSelected:
      buttonProps = {
        onClick: (e) => {
          e.stopPropagation()
          navigationDispatch({
            type: 'setCustomerProjectDrawerState',
            payload: {
              garageState: {
                isCustomerProjectDrawerOpen: true,
              },
            },
          })
        },
        children: <p className={contentClassNames}>{placeholder}</p>,
      }

      break
    case fitmentStates.noVehiclesInGarage:
      buttonProps = {
        testingName: 'add-vehicle-button',
        onClick: (e) => {
          e.stopPropagation()
          setCurrentTabForAudience(audience)
          navigationDispatch({
            type: 'setCustomerProjectDrawerState',
            payload: {
              garageState: {
                isCustomerProjectDrawerOpen: true,
              },
            },
          })
        },
        children: (
          <div className={contentClassNames}>
            <Svg
              size={1}
              icon={'garage'}
              tone={'contrast'}
              style={{ marginRight: '0.25rem' }}
            />
            {placeholder}
          </div>
        ),
      }
      break
  }

  return (
    <Button
      brand={'secondary'}
      size={'small'}
      fill
      type={'button'}
      className={styles.button}
      {...buttonProps}
      segmentEvent={createClickedSegmentEvent('MobileGarageHomePage')}
    />
  )
}

export default MobileGarageButton
